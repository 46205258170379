import React from 'react'
import {init} from "./RichtextHelpers/RichtextHelpers";

import ComplexEditor from "./ComplexEditor";

export const serifList = ["Georgia, serif", "Garamond, serif",];
export const sansSerifList = ["Arial, sans-serif", "Helvetica, sans-serif",];
export const monospaceList = ["Courier, monospace"];
export const fullList = sansSerifList.concat(serifList).concat(monospaceList);
export const noDeadlines = ["draftmsg", "failedmsg", "finishedmsg"];
export const possibleRanks = ["A", "B", "C", "D", "E"];


const initText = (initFullVal, initTextVal) => {
    let initVal;
    try {
        initVal = initFullVal
          ? (
              typeof initFullVal === "string"
              ? JSON.parse(initFullVal)
              : initFullVal
            )
          : initTextVal
            ? init(initTextVal)
            : init("");
        
    } catch (err) {
        initVal = init("")
    }
    return initVal;
}

/**
 *
 * @param initFullVal
 * @param initTextVal
 * @return {JSX.Element}
 * @constructor
 */
function RichTextEmail({
                      
                           initFullVal="",
                           initTextVal="",
                          
                       }) {
    return <ComplexEditor  value={initText(initFullVal, initTextVal)} />
};


export default React.memo(RichTextEmail);

