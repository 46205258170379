import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {NavLink} from "react-router-dom";
import { initBlog } from "../@utils/blogFunctions";
import {CalendarIcon} from "./Blog";
// @ts-ignore
import {DateTime} from "luxon";
import useScreenSize from "../@hooks/useScreenSize";
import {Button, Paper} from "@mui/material";
import { corporateBlue } from "../style/style";

const maxWidth = 1024;
const defaultPadding = 4;

const Blog = React.memo(() => {
  const [blogs, setBlogs] = React.useState({data: []});

  React.useLayoutEffect(() => {
    initBlog(setBlogs);
  },[]);

  const screen = useScreenSize();

  const {data} = blogs;


  const  p = screen.width < 600 ? 0.5*defaultPadding : defaultPadding;
  const isList = screen.width < 656 && screen.width >= 600;
  return (<Box
    // @ts-ignore
    sx={{pt: 8,
      width: "100vw",
      WebkitBackdropFilter: "blur(8px)",
      backdropFilter: "blur(8px)",
      minHeight: "calc(100vh - 200px)", }}
  >

    <Box
      sx={{
        color: "#000",
        width: `calc(100% - ${p*8})`,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        alignItems: "center",
        maxWidth: maxWidth,
        mx: "auto",
        p: p,
      }}
    >

      {
        data.map((data, index) => <BlogCard key={index}
                                            fullWidth={screen.width < 600}
                                            variant={isList?"caption":undefined}
                                            list={isList} data={data}/> )
      }

    </Box>
  </Box>)
})

export default Blog;


export function BlogCard({ data, list, fullWidth, variant }: { data: any, list: boolean, fullWidth: boolean }) {

  const {
    1: by,
    2: date,
    6: comments_length,
    0: id,
    3: title,
    4: short,
    10: src,
  } = data;


  if (list) {
    return (
      <Paper sx={{
        borderRadius: 0,
        my: 2,
        background: "#fff",
        width: "100%",
        height: 250,
        display: "flex", justifyContent: "flex-start", alignItems: "start",
      }}  >
        <Box sx={{
          height: 250,
          backgroundImage: `url(${src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: 250, }}>
        </Box>
        <Box sx={{p: 1,
          position: "relative",
          height: "calc(100% - 16px)", width: "calc(100% - 266px)", display: "flex",
          flexDirection: "column",
          justifyContent: "center", alignItems: "start"}}>
          <Box sx={{width: "calc(100% - 0px)", maxHeight: "calc(100% - 40px)", height: "calc(100% - 40px)", overflow: "hidden", textOverflow:"ellipsis"}}>
            <Box sx={{width: "calc(100% - 0px)"}}>
          <Typography variant={"h6"} sx={{fontWeight: "bold"}} >
            {title}
          </Typography>
          </Box>
          <Box sx={{width: "calc(100% - 0px)"}}>
              <Typography variant={variant}>
                {short}
              </Typography>
          </Box>
          </Box>
            <Box sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
height: 40,
              width: "100%", display: "flex", alignItems: "center", justifyContent: "end"}}>
                <Button component={NavLink} to={"/blog/id?"+id} size={"small"} sx={{
                  width: "100%",
                  height: 40,

                  borderRadius: 0,
                  borderTop: "1px solid #fff",
                  background: "#000",
                  color: "#fff",
                  "&:hover": {
                    background: "#fff",
                    color: corporateBlue,
                    borderTop: "1px solid #000",
                  }
                }} >
                  Lesen
                </Button>


            </Box>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper sx={{
      m:1,
      background: "#fff",
      width: fullWidth?"100%":280,
      borderRadius: 0,
    }}  >
      <Box sx={{
        width: "100%",
        maxHeight: 340, overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <img
          src={src}
          style={{maxWidth: "100%"}}
          alt="blog"
        />
      </Box>
      <Box sx={{p: 2}}>
        <Typography variant={"h6"} sx={{fontWeight: "bold"}} >
          {title}
        </Typography>
        <Box >
          <Box>
            <Typography variant={variant}>
              {short}
            </Typography>
          </Box>
          <Box sx={{width: "100%", mt:2,  display: "flex", alignItems: "center", justifyContent: "end"}}>
              <Button component={NavLink} to={"/blog/id?"+id} size={"small"} sx={{
                px: 4,
                width: "100%",
                borderRadius: 0,
                border: "1px solid #fff",
                background: "#000",
                color: "#fff",
                "&:hover": {
                  background: "#fff",
                  color: "#000",
                  border: "1px solid #000",
                }
              }} >
                Lesen
              </Button>

          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
