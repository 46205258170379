import React, {useCallback, useMemo,} from "react";
import {Editable, Slate, withReact, } from "slate-react";
import {createEditor,} from "slate";
import SlateErrorHandler from "../Core/helpers/SlateErrorHandler";
import { withEmbeds, withHtml} from "./RichtextHelpers/RichtextHelpers";
import Element from "./RichtextHelpers/Element";
import Leaf from "./RichtextHelpers/Leaf";
import {BlackShade} from "../Style";

function ComplexEditor({
	                       value,
                       }) {
	
	const editor = useMemo(() => withEmbeds(withHtml(withReact(createEditor()))), []);
	
	const renderElement = useCallback(props => <Element {...{
		...props,
		...{
			editable: null,
			att: null,
		}
	}} />, []);
	const renderLeaf = useCallback(props => <Leaf {...{
		...props, ...{
			color: '#c5c5c5',
			editable: false,
			//     sc: setChanger,
			att: null,
			fontSize: 16,
		}
	}} />, []);

	
	

	return <SlateErrorHandler>
		<Slate style={{}}
		       editor={editor}
		       initialValue={value}
		       value={value}
		       onChange={()=>{}}>
			
	
				
				<div
				     style={{
					     outline: 0,
					     textAlign: "start", whiteSpace: "wrap",
					     width: "100%",
					     fontFamily: "arial, sans-serif",
					     paddingTop: 4,
					     paddingBottom: 4,
					     color: BlackShade + "c0",
					     fontSize: 16,
				     }} >
					
					<Editable
						readOnly={true}
						style={{ outline: 0 }}
						renderElement={renderElement} renderLeaf={renderLeaf} />
				
			
			</div>
		
			
		</Slate>
	</SlateErrorHandler>
}

export default React.memo(ComplexEditor);

