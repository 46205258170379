import {post} from "./post";
import {crudOneDexie} from "./dexie/dexie";
// @ts-ignore
import {DateTime} from "luxon";
import { SetStateAction } from "react";

export const loadBlogEntry = async (setBlogDetails: {
  (value: SetStateAction<null>): void;
  (arg0: any): void;
}, setLoaded: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }, setError: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; },) => {
  try {
    let loadData = true;

    const id = window.location.search.slice(1);

    const cache = await crudOneDexie(id, "blog", "R");

    const now = DateTime.now();

    if (cache) {
      const cachetime = DateTime.fromISO(cache.date);
      const delta = now.diff(cachetime, ['hours']).toObject();
      loadData = Math.abs(delta.hours) > 6
    }
    if (loadData) {
      const query = {
        id
      }
      const res = await post("get-blog-entry", query);

      let success = false;

      if (res) {
        if (res.data) {
          const entry = res.data;

          setBlogDetails(entry);
          setLoaded(true);
          success = true;

          const cachetime = now.toISO();

          entry.id = entry[0];
          entry.date = cachetime;
          crudOneDexie(entry.id, "blog", "U+", entry).then(()=>{})
        }
      }

      if (!success) {
        setError(true);
      }

    } else if (cache) {
      setBlogDetails(cache);
      setLoaded(true);
    } else {
      setError(true)
    }
  } catch (e) {
    setError(true)
  }
}


export const getLatestEntries = async (setNews: Function) => {
  const page = 0;
  const amount = 8;
  const query = {
    page, amount
  }
  let loadData = true;
  const defaultId = "0";
  const cache = await crudOneDexie(defaultId, "news", "R");
  const now = DateTime.now();

  if (cache) {

    const cachetime = DateTime.fromISO(cache.date);
    const delta = now.diff(cachetime, ['hours']).toObject();
    loadData = Math.abs(delta.hours) > 6
  }
  if (loadData) {
    const res = await post("blogs", query);

    if (res) {
      if (res.data) {
        const {
          data
        } = res.data;
        setNews(data);
        const cachedata = {
          id: defaultId,
          data,
          date: now.toISO(),
        }
        await crudOneDexie(defaultId, "news", "U+", cachedata)
      }
    }
  } else {
    setNews(cache.data);
  }



}


export const initBlog = async (setBlog: { (value: SetStateAction<{ data: never[]; }>): void; (arg0: any): void; }) => {
  const page = 0;
  const amount = 10;
  const query = {
    page, amount
  }
  let loadData = true;
  const defaultId = "init";
  const cache = await crudOneDexie(defaultId, "blog", "R");
  const now = DateTime.now();

  if (cache) {

    const cachetime = DateTime.fromISO(cache.date);
    const delta = now.diff(cachetime, ['hours']).toObject();
    loadData = Math.abs(delta.hours) > 6
  }

  if (loadData) {
    const res = await post("blogs", query);

    if (res) {
      if (res.data) {
        const {
          data
        } = res.data;

        setBlog(res.data);

        const cachetime = now.toISO();
        const cachedata = {
          id: defaultId,
          data,
          date: cachetime,
        };

        for (let i=0;i<res.data.length;i+=1) {
          const entry = res.data[i];
          entry.id = entry[0];
          entry.date = cachetime;
          await crudOneDexie(entry.id, "blog", "U+", entry)
        }
        await crudOneDexie(defaultId, "blog", "U+", cachedata)
      }
    }
  } else if (cache) {
    setBlog(cache.data);
  }
}
