import {Canvas} from '@react-three/fiber'
import { Environment, Lightformer, ContactShadows, OrbitControls } from '@react-three/drei'
import { Effects } from './Effects'
import { Lamborghini } from './Lamborghini'
import React, {Suspense} from "react";

function Hero3d() {
	
	
	return (
		<Canvas
		        dpr={[1, window.devicePixelRatio]}
		        style={{
							width: "100vw",
			        height: "100vh", pointerEvents: "none",
			        position: "fixed", top: 0, left:0,
			        zIndex: 1
		}}
		        camera={{ position: [0, 0, 15], fov: 25 }}
		>
			<Suspense fallback={null}>
			<Lamborghini rotation={[0, Math.PI / 1.5, 0]} scale={0.015} />
			</Suspense>
			<hemisphereLight intensity={0.5} />
			<ContactShadows resolution={1024} frames={1} position={[0, -1.16, 0]} scale={15} blur={0.5} opacity={1} far={20} />
			<mesh scale={4} position={[3, -1.161, -1.5]} rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}>
				<ringGeometry args={[0.9, 1, 4, 1]} />
				<meshStandardMaterial color="#ffffff" roughness={0.75} />
			</mesh>
			<mesh scale={4} position={[-3, -1.161, -1]} rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}>
				<ringGeometry args={[0.9, 1, 3, 1]} />
				<meshStandardMaterial color="#2f7bea" roughness={0.75} />
			</mesh>
			{/* We're building a cube-mapped environment declaratively.
          Anything you put in here will be filmed (once) by a cubemap-camera
          and applied to the scenes environment, and optionally background. */}
			<Environment resolution={512}>
				{/* Ceiling */}
				<Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -9]} scale={[10, 1, 1]} />
				<Lightformer intensity={2} color="#2f7bea" rotation-x={Math.PI / 2} position={[0, 4, -6]} scale={[10, 1, 1]} />
				<Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
				<Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />
				<Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
				<Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]} />
				<Lightformer  intensity={2} color="#2f7bea"  rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]} />
				{/* Sides */}
				<Lightformer intensity={2}  rotation-y={Math.PI / 2} position={[-50, 2, 0]} scale={[100, 2, 1]} />
				<Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[50, 2, 0]} scale={[100, 2, 1]} />
				{/* Key */}
				<Lightformer form="ring" color="#2f7bea" intensity={10} scale={2} position={[10, 5, 10]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
			</Environment>
			{<Effects/>}
		<RotatingCam />
		</Canvas>
	)
}

const RotatingCam = () => {
	
	
	return 	<OrbitControls autoRotate={true} makeDefault={true}  enablePan={false} enableZoom={false} minPolarAngle={Math.PI / 2.2} maxPolarAngle={Math.PI / 2.2} />
}

export default React.memo(Hero3d);
