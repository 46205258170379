import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Link } from '@mui/material';
import { corporateBlue } from '../style/style';
import { InstagramEmbed } from 'react-social-media-embed';
import useScreenSize from "../@hooks/useScreenSize";
import {NavLink} from "react-router-dom";
import News from "../Blog/News";



const maxWidth = 1024;
const defaultPadding = 4;
let timer: NodeJS.Timeout | undefined;


const instagramPosts = [
  "https://www.instagram.com/reel/CvzLnAERPVU/",
  "https://www.instagram.com/reel/CsOh56MOAAO/",
  "https://www.instagram.com/reel/CvQTsUtI1rp/",
]

function LandingPage() {
  const [instaSlider1, setinstaSlider1] = React.useState(0);
  const [slider, setSlider] = React.useState(0);
  const screenSize = useScreenSize();

  const sliderRef = React.useRef(0);
  sliderRef.current = slider;

  React.useEffect(() => {
    if (timer) {
      clearInterval(timer);
    }
    timer = setInterval(()=>{
      setSlider((sliderRef.current+1)%3)
    }, 9000)
  }, [])




  return (
    <>
      <Box // @ts-ignore
        sx={{ p: defaultPadding, overflow:"hidden", height: "100vh",  position: "relative", zIndex: 2,}}>

        <Box
          style={{height: "100vh",
            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start"}}>
          <Typography variant={"caption"}>
            {"Schnell, Sicher und Mit Garantie!"}
          </Typography>

          <Typography  variant={"h3"}>
            {"Steigern Sie die Leistung Ihres Autos"}
          </Typography>

          <Typography>
            {"Von erfahrenen Ingenieuren durchgeführte Softwareoptimierung für messbare Leistungssteigerung."}
          </Typography>

          <NavLink to={"/booking"}>
          <Button sx={{
            mt: 5, borderRadius:0, padding: 2,
            color: "#fff",
            background: "#090909",
            "&:hover": {
              color: corporateBlue,
              background: "#090909",
            }

          }}>
            {"Jetzt Termin buchen"}
          </Button>
          </NavLink>
        </Box>

        {false && <div style={{
          position: "absolute",
          bottom: 60,
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
					<div onClick={() => setSlider(0)} style={{
            cursor: "pointer",
            width: 30,
            height: 5,
            background: slider === 0 ? corporateBlue : "#fff",
            margin: 10,
            borderRadius: 100
          }}></div>
					<div onClick={() => setSlider(1)} style={{
            cursor: "pointer",
            width: 30,
            height: 5,
            background: slider === 1 ? corporateBlue : "#fff",
            margin: 10,
            borderRadius: 100
          }}></div>
					<div onClick={() => setSlider(2)} style={{
            cursor: "pointer",
            width: 30,
            height: 5,
            background: slider === 2 ? corporateBlue : "#fff",
            margin: 10,
            borderRadius: 100
          }}></div>
				</div>}
      </Box>


      <Box sx={{
        p:defaultPadding, overflow: "hidden",


        position: "relative", zIndex: 2, background: "#090909"}}>


        <Box sx={{position: "absolute",
          pointerEvents: "none",
          top: 0, left: 0, opacity: 0.1, width: "100vw",height:"100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <img
            src={"/pexels-vlad-alexandru-popa-1402787.jpg"}
            style={{ filter: "blur(14px)",height: 6000}} />
        </Box>

        <Box sx={{width: "100%", maxWidth: maxWidth, mx: "auto", mb: 8}}>
          <Typography variant={"h5"} sx={{fontWeight: "bold", mb: 2}}>
            Sehen Sie echte Leistungssteigerungen in Aktion!
          </Typography>
          <Typography>
            Sie sind neugierig, wie unsere Softwareoptimierung in der Realität aussieht? Wir haben für Sie drei Videos ausgewählt, die die beeindruckende Leistungssteigerung durch unsere Services demonstrieren.
          </Typography>
        </Box>
        {
          screenSize.width>=735 ?
          <Box sx={{
            maxWidth: maxWidth, mx: "auto", flexWrap: screenSize.width < 735 ? "nowrap" : "wrap",
            width: "100%", display: "flex", justifyContent: "space-evenly", alignItems: "start",
          }}>
            <Box sx={{mt: 0, mr: screenSize.width < 735 ? "auto" : 0}}>
              <InstagramEmbed url="https://www.instagram.com/reel/CvzLnAERPVU/" width={328} height={505}/>

            </Box>
            <Box sx={{mt: screenSize.width < 735 ? 0 : 6, ml: screenSize.width < 735 ? "auto" : 0}}>
              <InstagramEmbed url="https://www.instagram.com/reel/CsOh56MOAAO/" width={328} height={505}/>

            </Box>
            <Box sx={{mt: screenSize.width < 735 ? 0 : 12, mr: screenSize.width < 735 ? "auto" : 0}}>
              <InstagramEmbed url="https://www.instagram.com/reel/CvQTsUtI1rp/" width={328} height={505}/>

            </Box>
          </Box>
            : <Box sx={{
              maxWidth: maxWidth, mx: "auto",
              width: "100%", display: "flex", justifyContent: "justify-start", alignItems: "start",
            }}>
              <Box sx={{mx: 0.5, marginLeft: -(instaSlider1)*328+"px"}} />
              {
                instagramPosts.map((x, i) => (
                  <Box key={x} sx={{mx: 0.5}}>
                    <InstagramEmbed url={x} width={328} height={505}/>
                  </Box>
                ))
              }
            </Box>
        }

        {/*  Arrow Navigation for Instagram Embed Slider  */}
        {screenSize.width<735 && <Box sx={{
          mt:4, mb:3,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <div onClick={() => setinstaSlider1(0)} style={{
            cursor: "pointer",
            width: 30,
            height: 5,
            background: instaSlider1 === 0 ? corporateBlue : "#fff",
            margin: 10,
            borderRadius: 100
          }}></div>
          <div onClick={() => setinstaSlider1(1)} style={{
            cursor: "pointer",
            width: 30,
            height: 5,
            background: instaSlider1 === 1 ? corporateBlue : "#fff",
            margin: 10,
            borderRadius: 100
          }}></div>
          <div onClick={() => setinstaSlider1(2)} style={{
            cursor: "pointer",
            width: 30,
            height: 5,
            background: instaSlider1 === 2 ? corporateBlue : "#fff",
            margin: 10,
            borderRadius: 100
          }}></div>
        </Box>
        }

        <Box sx={{width: "100%", maxWidth: maxWidth, mx: "auto", mt: 8}}>
          <Typography variant={"h5"} sx={{fontWeight: "bold", mb: 2}}>
            Echte Leistungssteigerung, Echte Ergebnisse
          </Typography>
          <Typography>
            Unsere Videos bieten mehr als nur Worte; sie präsentieren echte Ergebnisse. Von spürbarer Beschleunigung und beeindruckender Höchstgeschwindigkeit bis hin zur Optimierung für den Alltag – unsere zufriedenen Kunden sind der lebende Beweis für die Wirksamkeit unserer Softwareoptimierung. Mit der Sicherheit unserer Garantie im Rücken gibt es keinen besseren Zeitpunkt, die Leistung Ihres Autos auf das nächste Level zu bringen. Überzeugen Sie sich selbst und erleben Sie, wie weit Ihr Auto wirklich gehen kann!          </Typography>
        </Box>
      </Box>


      <Box sx={{
        zIndex: 3,
        pb: 6,
        position: "relative",
        flexWrap:"wrap",
        background: "#00000080",
        width: "100vw", color: "#fff",
        WebkitBackdropFilter: "blur(4px)",
        backdropFilter: "blur(4px)",}}>
        <Box sx={{
          mx: "auto",
          display: "flex", alignItems: "flex-start", justifyContent: "center",
          position: "relative", zIndex: 2, maxWidth, width: Math.min(screenSize.width, maxWidth),
        }}>
          <Card sx={{ maxWidth:  `calc(50% - ${screenSize.width < 565 ?1:20}px)`, width: "100%",
            marginBottom: "-1px",

            border: "1px solid transparent", "&:hover": {border: "1px solid "+corporateBlue, color: corporateBlue},
            borderRadius:0, background: "#090909", color: "#fff",}}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="240"
                sx={{transform: "scaleX(-1)"}}
                image={"https://backend.node.icom.sarl/downloads/F6hXMPcfbqr97v7uzBjehMCJ8gKUGicXdm47tkFKkrQ64f9f6d00565b58625a04e86"}
                alt=""
              />
              <CardContent sx={{color: 'inherit'}}>
                <Typography sx={{color: 'inherit'}} gutterBottom variant="h5" component="div">
                  Folierung
                </Typography>
                <Typography sx={{color: 'inherit'}} variant="body2" >
                  Steigern Sie nicht nur die Leistung, sondern auch den Look Ihres Autos mit unseren hochwertigen Folierungen. Von schlichter Eleganz bis hin zu auffallenden Designs, wir bieten maßgeschneiderte Lösungen, die Ihrem Fahrzeug einen einzigartigen Charakter verleihen. Dauerhaft, kratzfest und mit Garantie. Setzen Sie ein Statement auf der Straße!

                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions >
              <NavLink to={"/contact"} style={{ color: "inherit",width: "100%"}}>
              <Button sx={{mt: 5, borderRadius:0, width:"100%", borderTop: "1px solid #fff", padding: 2, color: "inherit", background: "#090909"}}>
                Anfragen
              </Button>
              </NavLink>
            </CardActions>
          </Card>
          <Card sx={{

            marginBottom: "-1px",
            maxWidth: `calc(50% - ${screenSize.width < 565 ?1:20}px)`, width: "100%",
            border: "1px solid #000", "&:hover": {border: "1px solid "+corporateBlue, color: corporateBlue},

            borderRadius:0, color: "#090909", background: "#fff" }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="240"
                image="https://backend.node.icom.sarl/downloads/F6hXMPcfbqr97v7uzBjehMCJ8gKUGicXdm47tkFKkrQ64f9f6c80565b58625a04e51"
                alt=""
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {screenSize.width < 565 ? "Leistung":"Leistungssteigerung"}

                </Typography>
                <Typography variant="body2" >
                  Erleben Sie Ihr Auto wie nie zuvor mit unserer professionellen Softwareoptimierung. Von Drehmomentsteigerung bis zu verbesserten Beschleunigungswerten – unser erfahrenes Ingenieurteam bietet messbare Leistungssteigerung, abgesichert durch unsere umfassende Garantie. Entfesseln Sie das wahre Potenzial Ihres Fahrzeugs!

                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <NavLink to={"/contact"} style={{ color: "inherit",width: "100%"}}>
              <Button sx={{mt: 5, borderRadius:0, width:"100%", borderTop: "1px solid #090909", padding: 2, color: "inherit", background: "#fff"}}>
                Anfragen
              </Button>
              </NavLink>
            </CardActions>
          </Card>
        </Box>

      </Box>
      <Box sx={{
        p: defaultPadding,
        overflow: "hidden",
        position: "relative", zIndex: 2, background: "#fff", color: "#000"}}>
        <div style={{
          pointerEvents: "none",
          filter: "blur(4px)",

          position: "absolute", top: 0, left: 0, opacity: 0.1, width: "100vw", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <img
            src={"/pexels-julia-kuzenkov-1974520.jpg"}
            style={{height: 2000,}} />
        </div>
        <div style={{marginLeft: "auto", marginRight: "auto", width: "100%", maxWidth: maxWidth}}>
          <Typography variant={"h4"} sx={{mt: 2, mb: 2, fontWeight: "bold"}}>
            Unsere Dienstleistungen
          </Typography>

          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold",whiteSpace: "wrap"}}>
            {screenSize.width < 565 ? "Leistung":"Leistungssteigerung"}
          </Typography>
          <Typography>
            Entfesseln Sie die wahre Power Ihres Fahrzeugs mit unserer spezialisierten Leistungssteigerung. Unser Ingenieurteam führt umfassende Anpassungen von Motor und Antriebssystem durch, um maximale Performance und Zuverlässigkeit zu gewährleisten. Alle unsere Leistungssteigerungen sind durch unsere individuellen Garantieoptionen abgesichert.

          </Typography>
          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Folierung / Teilfolierung
          </Typography>
          <Typography>
            Egal, ob Vollfolierung oder Teilfolierung – wir bieten Ihnen eine breite Palette an Farben und Designs, um Ihr Auto individuell zu gestalten. Unsere Folien sind kratzfest, UV-beständig und bieten eine zusätzliche Schutzschicht für den Lack. Genießen Sie die Ästhetik und den Schutz, den unsere Folierungen bieten.


          </Typography>
          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Abgasanlagen
          </Typography>
          <Typography>
            Optimieren Sie das Klangprofil und die Leistung Ihres Autos mit unseren maßgeschneiderten Abgasanlagen. Von leistungssteigernden Auspuffsystemen bis hin zu umweltfreundlicheren Optionen bieten wir eine breite Auswahl, die sowohl Performance als auch Nachhaltigkeit berücksichtigt.


          </Typography>

          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Fahrwerk
          </Typography>
          <Typography>
            Verbessern Sie die Straßenlage und das Handling Ihres Autos mit unseren spezialisierten Fahrwerklösungen. Wir bieten eine breite Palette von Fahrwerkskomponenten, von sportlich bis komfortabel, die alle auf Ihr spezifisches Fahrzeugmodell abgestimmt sind.

          </Typography>

          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Felgen
          </Typography>
          <Typography sx={{mb:2}}>
            Verleihen Sie Ihrem Auto mit unseren Premium-Felgen das gewisse Etwas. Wählen Sie aus einer Vielzahl von Designs und Materialien, um sowohl die Ästhetik als auch die Performance Ihres Fahrzeugs zu steigern. Unsere Felgen sind für ihre Haltbarkeit und Qualität bekannt.


          </Typography>

        </div>
      </Box>

      <Box sx={{
        p: defaultPadding,
         position: "relative", zIndex: 2, background: "#00000080",WebkitBackdropFilter: "blur(4px)",
        backdropFilter: "blur(4px)",}}>
        <Box sx={{
          py: 10,
          maxWidth, mx: "auto",
          width: "100%",
          display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start"}}>
          <Typography variant={"caption"}>
            Performance
          </Typography>

          <Typography  variant={"h3"}>
            Für alle Marken
          </Typography>

          <Typography>
            Individuelles Tuning
          </Typography>

          <NavLink to={"/tuningcheck"} >
          <Button sx={{mt: 5, borderRadius:0, padding: 2,

            background: "#fff",
            color: "#090909",
            "&:hover": {
              color: corporateBlue,
              background: "#090909",
            }}}>
            Zum Tuningcheck
          </Button>
          </NavLink>
        </Box>
      </Box>

      <Box sx={{
        p: defaultPadding, overflow: "hidden",
        position: "relative", zIndex: 2, background: "#090909", color: "#fff"}}>
        <div style={{
          pointerEvents: "none",
          position: "absolute", top: 0, left: 0, opacity: 0.1, width: "100vw", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <img
            src={"/pexels-vlad-alexandru-popa-1402787.jpg"}
            style={{height: 3000, filter: "blur(8px)"}} />
        </div>
        <div style={{marginLeft: "auto", marginRight: "auto", width: "100%", maxWidth: maxWidth}}>
          <Typography variant={"h6"} sx={{mt: 7, fontWeight: "bold"}}>
            Über uns
          </Typography>
          <Typography>
            Seit unserer Gründung haben wir uns als Spezialisten für automobile Leistungssteigerung und Individualisierung etabliert. Mit einem erfahrenen Ingenieurteam und stetigem Fokus auf Qualität und Kundenzufriedenheit haben wir Hunderte von Autos transformiert. Unsere langjährige Erfahrung und unser Engagement für Exzellenz machen uns zur ersten Wahl für Fahrzeugoptimierung.

          </Typography>
          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Das Team
          </Typography>
          <Typography>
            Unser Team besteht aus qualifizierten und leidenschaftlichen Ingenieuren, Technikern und Designern, die alle ein gemeinsames Ziel verfolgen: die Optimierung Ihres Fahrerlebnisses. Mit jahrelanger Erfahrung in der Automobilbranche bringt jedes Teammitglied spezialisiertes Wissen und Praxiserfahrung ein, um Ihr Auto sicher, effizient und leistungsstark zu gestalten. Ihr Auto ist bei uns in besten Händen.


          </Typography>
          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Unsere Philosophie
          </Typography>
          <Typography sx={{mb:8}}>
            Unsere Philosophie basiert auf drei Grundprinzipien: Qualität, Zuverlässigkeit und Kundenzufriedenheit. Wir streben stets danach, die Erwartungen unserer Kunden nicht nur zu erfüllen, sondern zu übertreffen, indem wir individuelle Lösungen und erstklassigen Service bieten. Jedes Projekt ist eine Gelegenheit, technische Exzellenz mit persönlichem Engagement zu kombinieren, um für Sie das beste Fahrerlebnis zu schaffen.


          </Typography>
        </div>
      </Box>
      <Box sx={{
        p: defaultPadding,
        overflow: "hidden",
        position: "relative", zIndex: 2, background: "#fff", color: "#000"}}>
        <div style={{
          pointerEvents: "none",
          filter: "blur(4px)",

          position: "absolute", top: 0, left: 0, opacity: 0.1, width: "100vw", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <img
            src={"/pexels-julia-kuzenkov-1974520.jpg"}
            style={{height: 2000,}} />
        </div>
        <div style={{marginLeft: "auto", marginRight: "auto", width: "100%", maxWidth: maxWidth}}>
          <Typography variant={"h4"} sx={{mt: 2, mb: 2, fontWeight: "bold"}}>
            Häufig gestellte Fragen (FAQs)
          </Typography>

          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Was ist Softwareoptimierung?

          </Typography>
          <Typography>
            Softwareoptimierung ist die Anpassung der Motorsteuerungssoftware, um die Leistung Ihres Fahrzeugs zu verbessern.

          </Typography>
          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Wie sicher ist die Leistungssteigerung?
          </Typography>
          <Typography>
            Unsere Ingenieure folgen strengen Qualitätsstandards. Zudem bieten wir individuelle Garantieoptionen für unsere Leistungssteigerungen an.



          </Typography>
          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Kann ich meine Folierung anpassen?
          </Typography>
          <Typography>
            Ja, wir bieten eine breite Palette an Farben und Designs für Voll- und Teilfolierungen.



          </Typography>

          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Beeinflusst eine neue Abgasanlage meine Emissionswerte?
          </Typography>
          <Typography>
            Unsere Abgasanlagen sind so konzipiert, dass sie die gesetzlichen Emissionsstandards erfüllen oder übertreffen.


          </Typography>

          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Gibt es Garantien auf Felgen und Fahrwerk?
          </Typography>
          <Typography sx={{mb:2}}>
            Ja, alle unsere Felgen und Fahrwerkskomponenten kommen mit einer umfassenden Garantie.



          </Typography>


          <Typography variant={"h6"} sx={{mt: 2, fontWeight: "bold"}}>
            Kann ich eine Kombination aus verschiedenen Dienstleistungen buchen?
          </Typography>
          <Typography sx={{mb:2}}>
            Selbstverständlich, wir bieten maßgeschneiderte Pakete an, die mehrere unserer Dienstleistungen kombinieren.
          </Typography>

        </div>
      </Box>

      <Box sx={{
        px: screenSize.width< 600 ? 0 : defaultPadding,
        py: defaultPadding,
        width: "100%", display: "flex", justifyContent: "space-evenly", alignItems: "center",
        position: "relative", zIndex: 2, background: "#00000080",WebkitBackdropFilter: "blur(4px)",
        backdropFilter: "blur(4px)",}}>


          <NavLink to={"/tuningcheck"} >
            <Button sx={{borderRadius:0, padding: 2,

              background: "#fff",
              color: "#090909",
              "&:hover": {
                color: corporateBlue,
                background: "#090909",
              }}}>
              Tuningcheck
            </Button>
          </NavLink>

        <NavLink to={"/booking"} >
          <Button
            variant={"contained"}
            sx={{borderRadius:0, padding: 2,

              background: corporateBlue,
              color: "#fff",
              "&:hover": {
                color: corporateBlue,
                background: "#fff",
              }
            }}>
            {screenSize.width< 600 ? "Termin": "Termin buchen"}
          </Button>
        </NavLink>

        <NavLink to={"/contact"} >
          <Button sx={{borderRadius:0, padding: 2,

            background: "#fff",
            color: "#090909",
            "&:hover": {
              color: corporateBlue,
              background: "#090909",
            }}}>
            Kontakt
          </Button>
        </NavLink>
      </Box>

      <News />
    </>
  );
}

export default LandingPage;
