import React, {Suspense} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Link} from '@mui/material';
import { Instagram } from '@mui/icons-material';
import LandingPage from "./Landing/LandingPage";
import {Route, Routes, useLocation} from "react-router";
import {NavLink} from "react-router-dom";

import Footer from "./Footer/Footer";

import Hero3d from "./Hero3d/Hero3d";
import {corporateBlue} from "./style/style";
import useScreenSize from "./@hooks/useScreenSize";
import StaticSite from "./@utils/writer/components/StaticSite/StaticSite";
const Blog = React.lazy(() => import("./Blog/Blog"));
const Blogs = React.lazy(() => import("./Blog/Blogs"));
const Tuning = React.lazy(() => import("./Tuning/Tuning"));
const Contact = React.lazy(() => import('./Contact/Contact'));
const SiteNotFound = React.lazy(() => import("./SiteNotFound/SiteNotFound"));
const AppointmentBooking = React.lazy(() => import("./AppointmentBooking/AppointmentBooking"));

const drawerWidth = 240;




function App() {

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const {pathname, search} = useLocation();

  // scroll to top an nav change
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const screenSize = useScreenSize();

  const displayLargeLogo = screenSize.width>660||(screenSize.width<600 && screenSize.width>345);

  return (
    <Box
      // @ts-ignore
      sx={{background: "#000"}}>
      <AppBar component="nav" sx={{
        background: "#000000ba",
        WebkitBackdropFilter: "blur(4px)",
        backdropFilter: "blur(4px)",
        overflow: "hidden",

      }}
      >
        <Box sx={{position: "absolute", top:0, left:0, width: "100vw", height: 80, opacity: 0.1, display: "flex", justifyContent: "center", alignItems: "center"}}>
          <img style={{width: "100%"}} src={"https://backend.node.icom.sarl/downloads/F6hXMPcfbqr97v7uzBjehMCJ8gKUGicXdm47tkFKkrQ64f9f6c50565b58625a04e39"} />
        </Box>
        <Toolbar>
          <Box >
            <Box sx={{"display": displayLargeLogo?"block":"none" }}>
              <NavLink to={"/"} style={{textDecoration: "none", color: "#fff"}}>
                <img
                  width={200}
                  src={"https://backend.node.icom.sarl/downloads/F6hXMPcfbqr97v7uzBjehMCJ8gKUGicXdm47tkFKkrQ64fae5a0702d4c863072db0b"} />

              </NavLink>
            </Box>

            <Box sx={{"display": !displayLargeLogo?"block":"none" }}>
              <NavLink to={"/"} style={{textDecoration: "none", color: "#fff"}}>
                <img
                  width={46}
                  src={"https://backend.node.icom.sarl/downloads/F6hXMPcfbqr97v7uzBjehMCJ8gKUGicXdm47tkFKkrQ64fae67c0d0674861aa3f2ab"} />

              </NavLink>
            </Box>
          </Box>
          <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center", ml: "auto"}}>
            <NavLink to={"/booking"} style={{textDecoration: "none", color: "#fff"}}>
              <Typography  sx={{px: 1, mx: 1,  color: '#fff',
                borderBottom: pathname === "/booking" ? "2px solid #fff" : "2px solid transparent",
                "&:hover": {
                color: corporateBlue,
                  borderBottom:  "2px solid " + corporateBlue,
                },
                "display": { xs: 'none', sm: 'block' } }}>
                Termin
              </Typography>
            </NavLink>
            <NavLink to={"/tuningcheck"} style={{textDecoration: "none", color: "#fff"}}>
              <Typography  sx={{px: 1, mx: 1,  color: '#fff',
                borderBottom: pathname === "/tuningcheck" ? "2px solid #fff" : "2px solid transparent",

                "&:hover": {
                  color: corporateBlue,
                  borderBottom:  "2px solid " + corporateBlue,
                },
                "display": { xs: 'none', sm: 'block' } }}>
                Tuning
              </Typography>
            </NavLink>
            <NavLink to={"/blogs"} style={{textDecoration: "none", color: "#fff"}}>
              <Typography  sx={{px: 1, mx: 1,  color: '#fff',
                borderBottom: pathname.includes("blog") ? "2px solid #fff" : "2px solid transparent",

                "&:hover": {
                  color: corporateBlue,
                  borderBottom:  "2px solid " + corporateBlue,
                },
                "display": { xs: 'none', sm: 'block' } }}>
                Blog
              </Typography>
            </NavLink>
            <NavLink to={"/contact"} style={{textDecoration: "none", color: "#fff"}}>

              <Typography  sx={{px: 1, mx: 1,  color: '#fff',
                borderBottom: pathname === "/contact" ? "2px solid #fff" : "2px solid transparent",

                "&:hover": {
                  color: corporateBlue,
                  borderBottom:  "2px solid " + corporateBlue,
                },
                "display": { xs: 'none', sm: 'block' } }}>
                Kontakt
              </Typography>
            </NavLink>

            <IconButton sx={{ color: '#fff',
              "&:hover": {
                color: corporateBlue,
              },
            }}>
              <Link
                href="https://www.instagram.com/truempperformance/"
                color="inherit"
                sx={{ mx: {xs: 0, sm: 1,} }}
              >
                <Instagram style={{position: "relative", top: 2}}/>
              </Link>
            </IconButton>

            <IconButton
              sx={{ position: "relative",
                "&:hover": {color: corporateBlue}, top: 2, mx: {xs: 0, sm: 1,}, "display": { xs: 'block', sm: 'none' } }}
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}

            >
              <MenuIcon />
            </IconButton>

          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <div style={{display: "flex", cursor:"pointer", alignItems: "center", justifyContent: "flex-start", height: 40, padding: 10, marginLeft: 6}}>

              <Typography
                onClick={handleDrawerToggle}
                variant="h6" sx={{height: 40, mt: 1}}>
                MP Performance
              </Typography>
            </div>
            <Divider />
            <List>


              <ListItem disablePadding      to={"/"}  component={NavLink}
              >
                <ListItemButton>
                  <Box

                    color="inherit"
                    sx={{ pl: 1, pr: 1, opacity: 0 }}
                  >
                    <Instagram sx={{opacity: 0}} />
                  </Box>
                  <Typography>Home</Typography>

                </ListItemButton>
              </ListItem>


              <ListItem disablePadding      to={"/tuningcheck"}  component={NavLink}
              >
                <ListItemButton>
                  <Box

                    color="inherit"
                    sx={{ pl: 1, pr: 1,  }}
                  >
                    <Instagram sx={{opacity: 0}} />
                  </Box>
                  <Typography>Tuningcheck</Typography>

                </ListItemButton>
              </ListItem>

              <ListItem disablePadding      to={"/booking"}  component={NavLink}
              >
                <ListItemButton>
                  <Box

                    color="inherit"
                    sx={{ pl: 1, pr: 1, opacity: 0 }}
                  >
                    <Instagram sx={{opacity: 0}} />
                  </Box>
                  <Typography>Termin buchen</Typography>

                </ListItemButton>
              </ListItem>

              <ListItem disablePadding   to={"/contact"}  component={NavLink}
              >
                <ListItemButton>
                  <Box

                    color="inherit"
                    sx={{ pl: 1, pr: 1, opacity: 0 }}
                  >
                    <Instagram />
                  </Box>
                  <Typography>Kontakt</Typography>

                </ListItemButton>
              </ListItem>
              <ListItem  disablePadding      to={"/blogs"}   component={NavLink}
              >

                <ListItemButton >
                  <Box

                    color="inherit"
                    sx={{ pl: 1, pr: 1, opacity: 0 }}
                  >
                    <Instagram sx={{opacity: 0}} />
                  </Box>
                  <Typography>Blog</Typography>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding
                        href={"https://www.instagram.com/truempperformance/"}
                        component={"a"}
              >

                <ListItemButton >
                  <Box

                    color="inherit"
                    sx={{ pl: 1, pr: 1 }}
                  >
                    <Instagram sx={{opacity: 0}} />
                  </Box>
                  <Typography>Instagram</Typography>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Box>

      <Hero3d />
      <Box sx={pathname==="/" ? {position: "relative", zIndex: 2,} : {

        width: "100vw",
        minHeight: "100vh",
        height: "auto",
        position: "relative",
        WebkitBackdropFilter: "blur(8px)",
        backdropFilter: "blur(8px)",
        zIndex: 2,}
      }>

        <Suspense fallback={null}>
      <Routes>

        <Route path="/impress" element={<StaticSite />} />
        <Route path="/terms-condition" element={<StaticSite />} />
        <Route path="/privacy-policy" element={<StaticSite />} />
        <Route path="/about" element={<StaticSite />} />

        <Route path="/" element={<LandingPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/id" element={<Blog />} />
        <Route path="/booking" element={<AppointmentBooking />} />
        <Route path="/tuningcheck" element={<Tuning />} />
        <Route path="*" element={<SiteNotFound />} />

      </Routes>
        </Suspense>

      <Footer />
      </Box>
    </Box>
  )

}

export default App;
