import React from "react";
// @ts-ignore
import {DateTime} from "luxon";
import {useLocation} from "react-router-dom";
import {crudOneDexie} from "../@utils/dexie/dexie";
import {post} from "../@utils/post";




export default function useStaticSite(breadcrumb=[{ name: "home", path: "/" }]) {

	const [pageData, setPageData] = React.useState(null);
	const location = useLocation();


	React.useLayoutEffect(() => {
		const getSite = async () => {
			let loadData = true;

			const url = window.location.pathname;

			const cache = await crudOneDexie(url, "blog", "R");

			const now = DateTime.now();

			if (cache) {
				const cachetime = DateTime.fromISO(cache.date);
				const delta = now.diff(cachetime, ['hours']).toObject();
				loadData = Math.abs(delta.hours) > 6;
				setPageData(cache);
			}
			if (loadData) {
				const query = {
					url
				}
				const res = await post("get-static-site", query);

				if (res) { // @ts-ignore
					if (res.data) { // @ts-ignore
						const entry = res.data;


						const cachetime = now.toISO();

						entry.id = url;
						entry.date = cachetime;
						setPageData(entry);

						await crudOneDexie(entry.id, "blog", "U+", entry)
					}
				}

			}
		}
		getSite();
	}, [location]);


	const bc = [...breadcrumb];

	if (pageData) {
		bc.push({name: pageData[2], path: pageData[1]},)
	}


	return {
		pageData,
		breadcrumb: bc,
		pagetitle: pageData ? pageData[2] : "",
	}
}
