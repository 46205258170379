import React from "react";
import useStaticComponents from "../../../../@hooks/@useStaticComponents";
import Box from "@mui/material/Box";

function StaticSite({}) {
	
	const {Breadcrumb, Body} = useStaticComponents([
		{ name: "Home", path: "/" },
	])
	
	return (
		<>
			<Box sx={{width: "100%", mb: 2}}>
				<Breadcrumb />
			</Box>
			<Box  sx={{width: "100%",
				backgroundImage: `url(${"https://backend.node.icom.sarl/downloads/F6hXMPcfbqr97v7uzBjehMCJ8gKUGicXdm47tkFKkrQ64f9f6d30565b58625a04ea6"})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				px: 5,
				mb: 2, maxWidth: 800, mx: "auto"}}>
				<Box  sx={{width: "100%",
					ml: -5,
				 maxWidth: 800,  background: "#ffffffe0",	p: 5,
					backdropFilter: "blur(4px)",}}>

				<Body />
				</Box>
			</Box>
		</>
	);
}

export default React.memo(StaticSite)
