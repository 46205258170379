import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {NavLink} from "react-router-dom";
import {getLatestEntries, initBlog} from "../@utils/blogFunctions";
import {CalendarIcon} from "./Blog";
// @ts-ignore
import {DateTime} from "luxon";
import useScreenSize from "../@hooks/useScreenSize";
import {Button, Paper} from "@mui/material";
import { BlogCard } from "./Blogs";

const maxWidth = 1024;
const defaultPadding = 4;

const News = React.memo(() => {
  const [news, setNews] = React.useState([]);

  React.useLayoutEffect(() => {
    getLatestEntries(setNews)
  },[]);

  const screen = useScreenSize();



  return (<Box
    // @ts-ignore
    sx={{
      width: "100vw",
      WebkitBackdropFilter: "blur(8px)",
      backdropFilter: "blur(8px)", }}
  >

    <Box
      sx={{
        color: "#000",
        width: `calc(100% - ${defaultPadding*8})`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: maxWidth,
        mx: "auto",
        p: defaultPadding,
      }}
    >

      {
        news.map((data, index) => <BlogCard key={index}
                                            fullWidth={true}
                                            variant={screen.width >= 600 && screen.width < 750?"caption":undefined}

                                            list={screen.width >= 600} data={data}/> )
      }

    </Box>
  </Box>)
})

export default News;
