import React from "react";
import useStaticSite from "./@useStaticSite";
import RichTextEmail from "../@utils/writer/components/RichTextEmail";
import {NavLink} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


function BreadcrumbCom({
												 paths = [{ name: "home", path: "/" }],
											 }) {
	return (
		<>
			{paths && paths.length > 0 && (
				// @ts-ignore
				<Box
					style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}
					//className="breadcrumb-wrapper font-400 text-[13px] text-qblack mb-[23px]"
				>
					{paths.map((path) => (
						<React.Fragment  key={path.name}>
              <NavLink to={path.path}>
                <Typography sx={{mx: 1, color: "#fff"}}
								>{path.name}</Typography>
              </NavLink>
              <Typography sx={{mx: 1}}
              >/</Typography>
            </React.Fragment>
					))}
				</Box>
			)}
		</>
	);
}

function PageTitle({ title, breadcrumb = [] }: { title: string; breadcrumb: any[] }) {
	return (
		<Box sx={{width: "100%",  pt: 10}} >
			<Box sx={{mx: "auto"}}>
				<Box sx={{mb: 2}}>
					<BreadcrumbCom paths={breadcrumb} />
				</Box>
				<Box style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
					<Typography variant="h4" sx={{fontWeight: "bold"}}>{title}</Typography>
				</Box>
			</Box>
		</Box>
	);
}


export default function useStaticComponents(breadCrumb: any[] = []) {
	const {
		breadcrumb,
		pagetitle,
		pageData,
	} = useStaticSite(breadCrumb);

	const Breadcrumb = React.useCallback(() => <PageTitle
		// @ts-ignore
		breadcrumb={breadcrumb}
		title={pagetitle}
	/>,[breadcrumb, pagetitle]);


	const Body = React.useCallback(() => (
		pageData ? <RichTextEmail
			key={"fix"}
			// @ts-ignore
			isEditable={false}
			initFullVal={pageData[3]}
			heightGrow={true}
			displayMenus={false}
		/> : null
	), [pageData])

	return {
		Breadcrumb,
		Body,
	}
}
