import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";
import {NavLink} from "react-router-dom";

const defaultPadding = 4;

export default function Footer() {
	return (
		<Box
			component="footer"
			sx={{
				mx: "auto",
				position: "relative", zIndex: 2,
				color: "#fff",
				backgroundColor: "#222222",
				p: defaultPadding,
			}}
		>
			<Box>
				<Grid container spacing={5}>
					<Grid item xs={12} sm={4}>
					
						<NavLink  to={"/about"}>
							<Typography sx={{color:"#fff"}} variant="h6" gutterBottom>
								Über uns
							</Typography>
						</NavLink>
						
						<Typography variant="body2" >
							Wir sind MP Performance. Service und Leidenschaft auf vier Rädern ist das wofür wir stehen.
						</Typography>
					</Grid>
					<Grid item xs={12} sm={4}>
						
						<NavLink  to={"/contact"}>
							<Typography sx={{color:"#fff"}} variant="h6" gutterBottom>
								Kontakt
							</Typography>
						</NavLink>
						<NavLink  to={"/impress"}>
							<Typography sx={{color:"#fff"}} variant="body2" >
								Impressum
							</Typography>
						</NavLink>
						<NavLink to={"/privacy-policy"}>
							<Typography sx={{color:"#fff"}} variant="body2" >
								Datenschutz
							</Typography>
						</NavLink>
						<NavLink to={"/terms-condition"}>
							<Typography sx={{color:"#fff"}} variant="body2" >
								AGB
							</Typography>
						</NavLink>
						
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography variant="h6" gutterBottom>
							 Folgen auf
						</Typography>
						<Link href="https://www.facebook.com/people/MP-Performance/100094714454830/" color="inherit">
							<Facebook />
						</Link>
						<Link
							href="https://www.instagram.com/truempperformance/"
							color="inherit"
							sx={{ pl: 1, pr: 1 }}
						>
							<Instagram />
						</Link>
					
					</Grid>
				</Grid>
				<Box mt={5}>
					<Typography variant="body2"  align="center">
						{"Copyright © "}
						<a color="inherit" href="mailto:andreas.roth@cloudplication.com">
							Andreas Roth
						</a>{" "}
						{new Date().getFullYear()}
						{"."}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
