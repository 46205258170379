import axios from "axios";


export const post = async (url:string, data: object, config=null) => {
  const main = process.env.REACT_APP_SERVER_PATH;
  let res = {};
  try {
    // @ts-ignore
    data._route = process.env.REACT_APP_ROUTE;
    const config = {};
    res = await axios.post(main+url, data, config);
  } catch (err) {
    console.log(err)
  }
  return res;
}
