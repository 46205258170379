import {Editor, Element as SlateElement, Node, Transforms} from "slate";
import {jsx} from "slate-hyperscript";


export const isBlockActive = (editor, format) => {
	const [match] = Editor.nodes(editor, {
		match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
	});
	return !!match
};

export const LIST_TYPES = ['ol', 'ul'];

export const toggleBlock = (editor, format,) => {
	
	const isActive = isBlockActive(editor, format);
	const isList = LIST_TYPES.includes(format);
	Transforms.unwrapNodes(editor, {
		match: n =>
			LIST_TYPES.includes(
				!Editor.isEditor(n) && SlateElement.isElement(n) && n.type
			),
		split: true,
	});
	const newProperties = {
		type: isActive ? 'paragraph' : isList ? 'list-item' : format,
	};
	Transforms.setNodes(editor, newProperties);
	if (!isActive && isList) {
		const block = {
			type: format, children: [],
		};
		Transforms.wrapNodes(editor, block)
	}
};


export const withHtml = (editor) => {
	const {insertData, isInline, isVoid} = editor;
	
	editor.isInline = element =>  element.type === 'link' ? true : isInline(element);
	
	editor.isVoid = element => element.type === 'image' ? true : isVoid(element);
	editor.isVoid = element => element.type === 'video' ? true : isVoid(element);
	editor.isVoid = element => element.type === 'audio' ? true : isVoid(element);
	
	editor.insertData = data => {
		try {
			const html = data.getData('text/html');
			if (html) {
				const parsed = new DOMParser().parseFromString(html, 'text/html');
				const fragment = deserialize(parsed.body);
				Transforms.insertFragment(editor, fragment);
				return
			}
			insertData(data)
		} catch (e) {
			//console.log({e: e, v: "htm"});
		}
	};
	return editor
};


export const deserialize = el => {
	if (el.nodeType === 3) {
		return el.textContent
	} else if (el.nodeType !== 1) {
		return null
	} else if (el.nodeName === 'BR') {
		return '\n'
	}
	
	const {nodeName} = el;
	
	
	let parent = el;
	
	if (
		nodeName === 'PRE' &&
		el.childNodes[0] &&
		el.childNodes[0].nodeName === 'CODE'
	) {
		parent = el.childNodes[0]
	}
	const children = Array
		.from(parent.childNodes)
		.map(deserialize)
		.flat()
		.filter(x => x.text !== "");
	
	if (
		children.length === 0
	) {
		
		children.push({text: ""})
	}
	
	if (el.nodeName === 'BODY') {
		try {
			return jsx('fragment', {}, children)
		} catch (e) {
			//console.log({e: e, v: "bodtags"});
		}
	}
	
	if (ELEMENT_TAGS[nodeName]) {
		try {
			const attrs = ELEMENT_TAGS[nodeName](el);
			extractStyle(attrs);
			return jsx('element', attrs, children)
		} catch (e) {

			return children;
		}
	}
	
	if (TEXT_TAGS[nodeName]) {
		try {
			const attrs = TEXT_TAGS[nodeName](el);
			extractStyle(attrs);
			return children.map(child => jsx('text', attrs, child))
		} catch (e) {

			return children;
		}
	}
	
	return children
};

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
const extractStyle = (attrs) => {
	const attStyle = attrs.style || null;
	
	let style = {};
	
	if (attStyle) {
		const styleElements = attStyle.split(";")
		
		styleElements.forEach(entry => {
			let [key, value] = entry.split(": ");
			key = key.replaceAll(" ", "");
			const uppercasingElements = key.split("-");
			let uppercased = uppercasingElements[0];
			for (let i=1;i<uppercasingElements.length;i+=1) {
				uppercased += capitalizeFirstLetter(uppercasingElements[i]);
			}
			
			
			if (uppercased && value) {
				style[uppercased] = value;
			}
		})
	}
	
	attrs.style = style;
}

export const ELEMENT_TAGS = {
	A: el => ({type: 'link', url: el.getAttribute('href'), style: el.getAttribute('style')}),
	BLOCKQUOTE: (el) => ({type: 'quote', style: el.getAttribute('style')}),
	H1: (el) => ({type: 'H1', style: el.getAttribute('style')}),
	H2: (el) => ({type: 'H2:', style: el.getAttribute('style')}),
	H3: (el) => ({type: 'H3: (', style: el.getAttribute('style')}),
	H4: (el) => ({type: 'H4:', style: el.getAttribute('style')}),
	H5: (el) => ({type: 'H5', style: el.getAttribute('style')}),
	H6: (el) => ({type: 'H6', style: el.getAttribute('style')}),
	IMG: el => ({type: 'image', url: el.getAttribute('src'), style: el.getAttribute('style')}),
	VIDEO: el => ({type: 'video', url: el.getAttribute('src'), style: el.getAttribute('style')}),
	AUDIO: el => ({type: 'audio', url: el.getAttribute('src'), style: el.getAttribute('style')}),
	FIGCAPTION: el => ({type: 'figcaption', url: el.getAttribute('src'), style: el.getAttribute('style')}),
	PICTURE: el => ({type: 'picture', url: el.getAttribute('src'), style: el.getAttribute('style')}),
	
	LI: (el) => ({type: 'list-item', style: el.getAttribute('style')}),
	OL: (el) => ({type: 'ol', style: el.getAttribute('style')}),
	P: (el) => ({type: 'paragraph', style: el.getAttribute('style')}),
	PRE: (el) => ({type: 'code', style: el.getAttribute('style')}),
	FIGURE: (el) => ({type: 'figure', style: el.getAttribute('style')}),
	SECTION: (el) => ({type: 'section', style: el.getAttribute('style')}),
	UL: (el) => ({type: 'ul', style: el.getAttribute('style')}),
	DIV: (el) => ({type: 'div', style: el.getAttribute('style')}),
	SPAN: (el) => ({type: "span", style: el.getAttribute('style')}),
	
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
export const TEXT_TAGS = {
	CODE: (el) => ({code: true, style: el.getAttribute('style')}),
	DEL: (el) => ({strikethrough: true, style: el.getAttribute('style')}),
	EM: (el) => ({italic: true, style: el.getAttribute('style')}),
	I: (el) => ({italic: true, style: el.getAttribute('style')}),
	S: (el) => ({strikethrough: true, style: el.getAttribute('style')}),
	STRONG: (el) => ({bold: true, style: el.getAttribute('style')}),
	U: (el) => ({underline: true, style: el.getAttribute('style')}),
};

export const init = (val) => [
	{
		type: 'paragraph',
		children: [
			{text: val},
		],
	},
];

export const serializedToPlain = (nodes) => {
	return nodes.map(n => Node.string(n)).join("\n")
};


export const isMarkActive = (editor, format) => {
	const marks = Editor.marks(editor);
	return marks ? marks[format] === true : false;
};


export const toggleMark = (editor, format, addProps) => {
	const isActive = isMarkActive(editor, format);
	if (isActive) {
		Editor.removeMark(editor, format)
	} else if (addProps) {
		Editor.addMark(editor, format, addProps)
	} else {
		Editor.addMark(editor, format, true)
	}
};


export const withEmbeds = editor => {
	// 4 video embeds
	editor.isVoid = element => element.type === 'video' || element.type === 'image';
	//editor.isInline = element => returnInlines(element.type);
	return editor
};


export const styleGenerator = (obj, prevStyling, pdf) => {
	let style = prevStyling ? {...prevStyling, ...obj} : {...obj};
	if (obj.color) {
		style.color = obj.color;
	} else if (!style.color) {
		style.color = "#303030";
	}
	
	if (obj.column) {
		style.marginLeft = 0;
		style.marginBottom = 0;
		style.marginRight = 0;
		style.marginTop = 0;
		style.display = "flex";
		if (obj.justifyContent) {
			style.justifyContent = obj.justifyContent;
		} else {
			style.justifyContent = "flex-start";
		}
		if (obj.alignItems) {
			style.alignItems = obj.alignItems;
			
		} else {
			style.alignItems = "flex-start";
		}
		if (obj.minWidth) {
			style.minWidth = obj.minWidth;
		} else {
			style.minWidth = pdf ? "40mm" : 300;
		}
		style.maxWidth = style.minWidth;
		style.flexDirection = "column";
	}
	if (obj.fontSize) {
		style.fontSize = parseInt(obj.fontSize);
	}
	if (obj.sup) {
		style.display = "inline-block";
		style.position = "relative";
		style.left = 0;
		style.bottom = pdf ? 2 : 5;
		style.fontSize = 7;
	}
	if (obj.sub) {
		style.display = "inline-block";
		style.position = "relative";
		style.left = 0;
		style.top = pdf ? 7 : 5;
		style.fontSize = 7;
	}
	if (obj.bold) {
		style.fontWeight = "bold";
	}
	if (obj.italic) {
		style.fontStyle = "italic";
	}
	
	if (obj.strikethrough) {
		style.textDecoration = "line-through";
	}
	if (obj.underline) {
		if (style.textDecoration) {
			style.textDecoration = "underline " + style.textDecoration;
		} else {
			style.textDecoration = "underline";
		}
	}
	
	if (obj.backgroundColor) {
		style.backgroundColor = obj.backgroundColor;
	}
	// todo
	if (obj.opacity) {
		style.opacity = obj.opacity;
	}
	if (obj.letterSpacing) {
		style.letterSpacing = obj.letterSpacing;
	}
	if (obj.lineHeight) {
		style.lineHeight = obj.lineHeight;
	}
	if (obj.textDecorationColor) {
		style.textDecorationColor = obj.textDecorationColor;
	}
	
	if (obj.textIndent) {
		style.textIndent = obj.textIndent;
	}
	if (obj.textTransform) {
		style.textTransform = obj.textTransform;
	}
	if (obj.marginTop) {
		style.display = "inline-block";
		style.marginTop = obj.marginTop;
	}
	if (obj.marginRight) {
		style.marginRight = obj.marginRight;
	}
	if (obj.marginBottom) {
		style.display = "inline-block";
		style.marginBottom = obj.marginBottom;
	}
	if (obj.marginLeft) {
		style.marginLeft = obj.marginLeft;
	}
	
	if (obj.paddingTop) {
		style.display = "inline-block";
		style.paddingTop = obj.paddingTop;
	}
	if (obj.paddingLeft) {
		style.paddingLeft = obj.paddingLeft;
	}
	if (obj.paddingBottom) {
		style.display = "inline-block";
		style.paddingBottom = obj.paddingBottom;
	}
	if (obj.paddingRight) {
		style.paddingRight = obj.paddingRight;
	}
	
	if (obj.borderTop) {
		style.borderTop = obj.borderTop;
	}
	if (obj.borderBottom) {
		style.borderBottom = obj.borderBottom;
	}
	if (obj.borderLeft) {
		style.borderLeft = obj.borderLeft;
	}
	if (obj.borderRight) {
		style.borderRight = obj.borderRight;
	}
	return style;
};
